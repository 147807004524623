<template>
  <VCard>
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Имя"
                name="instrumentName"
                persistent-hint
                hint="имя инстурмента (обязательное)"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :disabled="entity.id !== null && entity.id !== undefined"
                :value="entity.code"
                label="Код"
                placeholder="skilldev"
                name="instrumentCode"
                persistent-hint
                hint="кодовое имя инстурмента (уникальное, не пустое, редактировать нельзя)"
                @input="$emit('update:code', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.service"
                label="Сервис"
                placeholder="TALENT_ADDRESS"
                name="instrumentService"
                persistent-hint
                hint="env адрес сервиса"
                @input="$emit('update:service', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'InstrumentForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание инструмента';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование инструмента';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
