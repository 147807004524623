<template>
  <TTView>
    <VRow>
      <VCol>
        <InstrumentForm
          :entity="instrument"
          @update:name="instrument.name = $event"
          @update:code="instrument.code = $event"
          @update:service="instrument.service = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import InstrumentForm from '../../components/competency-matrix/instruments/InstrumentForm.vue';

export default {
  name: 'InstrumentEdit',

  components: {
    InstrumentForm,
  },

  data() {
    return {
      loading: false,
      instrument: { name: null, code: null, service: null },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { instrumentId: id } = this.$route.params;

        const data = { id };

        const { instrument } = await this.$di.api.CompetencyMatrix.InstrumentsGet({ data });

        this.instrument = instrument;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;
        const {
          instrument: {
            id, name, service,
          },
        } = this;

        const data = { id, instrument: { name, service } };

        await this.$di.api.CompetencyMatrix.InstrumentsUpdate({ data });

        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
